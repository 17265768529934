import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `8-eugene-crecent-christies-downs-south-australia`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const EugeneCrescentChristieDownsSA5164 = () => (
  <Layout>
    <Seo
      title="High Physical Support SDA Property in Christies Downs"
      description="Explore a High Physical Support SDA property in Christies Downs, offering fully accessible living with modern features for enhanced comfort and independence."
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default EugeneCrescentChristieDownsSA5164
